import React, { useRef } from 'react'
import Navbar from '../../components/NavBar';
import LOGO from '../../assets/UI/logo.png';
import LOGOWHITE from '../../assets/UI/LogoWhite.svg';
import BannerPurchase from '../../assets/UI/banner-purchase.png';
import BannerPurchaseRewards from '../../assets/UI/banner-purchase-rewards.png';
import MembershipCard from '../../assets/UI/membership-card.png';
import SlideBreak from '../../components/SlideBreak';
import Footer from '../../components/Footer';
import DoneIcon from '../../assets/UI/done.svg';
import DisabledIcon from '../../assets/UI/disabled.svg';
import DoneWhite from '../../assets/UI/doneWhite.svg';


function Purchase() {
    const aboutUsRef = useRef(null);
    const memberRef = useRef(null);
    const activityRef = useRef(null);
    return (
        <div className="page-purchase">
            <Navbar sections={{ aboutUsRef, memberRef, activityRef }}></Navbar>
            {/* <img className="w-full object-cover" src={BannerPurchase} alt="BannerPurchase" /> */}
            <div className=" w-full min-h-[100%] flex-row gap-[68px] flex overflow-hidden justify-center pt-20 pb-20 "
                style={{ backgroundImage: `url(${BannerPurchase})` }}
            >
                <div className="w-[501px] gap-20 items-center justify-around max-lg:flex-col ">
                    <div className="justify-start items-start flex-col flex">
                        <div className="Content flex-col justify-start items-start flex">
                            <div className="w-50 flex-col justify-start items-start flex">
                                <div className=" text-[#060D0D] text-2xl font-normal justify-start items-start flex flex-col font-['Montserrat'] tracking-tight max-sm:text-[16px]">
                                    <div className="flex flex-shrink-0 items-center gap-4 w-[8.875rem]">
                                        <img src={LOGO} alt="HSB Logo" />
                                        <div className="HsbFc w-[74px] h-6 text-zinc-950 text-[19px] font-exbold font-['Neue Plak']">
                                            HSB FC
                                        </div>
                                    </div>
                                    <div className="pt-5 after:text-[#060D0D] text-5xl font-exbold">
                                        Welcome to the club
                                    </div>
                                    <div className=" pt-6 text-lg font-light text-[#060D0DBF] font-['Neue Plak']">
                                        Với số lượng hiện tại là 42 thành viên chính thức. Chúng tôi hi vọng có thể sẽ đồng hành cùng với nhiều anh em khác trong những chặng đường sắp tới.
                                    </div>
                                    <div className="pt-6">
                                        <div className="rounded-sm border-[#060D0D] border-solid px-8 py-4 border-2 font-exbold text-lg">
                                            Our activities
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" w-[501px] max-sm:w-full h-fit flex-col justify-start items-center inline-flex">
                    <img className="w-full object-cover" src={MembershipCard} alt="MembershipCard" />
                </div>
            </div>
            <SlideBreak
                titles={[
                    "HANOI START-UP BROTHERS FC",
                    "WITHOUT FOOTBALL MY LIFE IS NOTHING",
                    "HANOI START-UP BROTHERS FC",
                    "WITHOUT FOOTBALL MY LIFE IS NOTHING",
                    "HANOI START-UP BROTHERS FC",
                ]}
            />
            <div
                style={{ backgroundImage: `url(${BannerPurchaseRewards})` }}
            >
                <div className="text-center">
                    <div className="pt-10 after:text-[#060D0D] text-5xl font-exbold">
                        Member Rewards
                    </div>
                    <div className="pt-3 text-lg font-light text-[#060D0DBF] font-['Neue Plak']">
                        Tất cả các thành viên tham gia câu lạc bộ của chúng tôi đều sẽ được hưởng mọi quyền lợi tùy vào các gói dưới đây.
                    </div>
                </div>
                <div className="pt-[64px] pb-[104px] ">
                    <div className="flex justify-around">
                        <div className="rounded-md border-[#060D0D] border-solid border-2 px-[91px] pt-[81px] ">
                            <div className="flex flex-shrink-0 items-center gap-4">
                                <img src={LOGO} alt="HSB Logo" />
                                <div className="HsbFc h-11 text-zinc-950 text-[36px] font-exbold font-['Neue Plak']">
                                    HSB FC
                                </div>
                            </div>
                            <div className="text-center pt-5">
                                <div className="text-lg font-normal font-['Neue Plak'] text-[#1D072E]">
                                    ONE MONTH
                                </div>
                                <div className="pt-2 text-5xl font-normal font-['Neue Plak'] text-[#1D072E]">
                                    $ 5.99
                                </div>
                            </div>
                            <div className="w-[170px] pt-8 pl-6">
                                <ul className="divide-y divide-dashed">
                                    <li className="flex font-normal text-sm text-[#1D072EBF] pb-1">
                                        <img src={DoneIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#1D072EBF] py-1 ">
                                        <img src={DoneIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#1D072EBF] py-1">
                                        <img src={DoneIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                </ul>
                            </div>
                            <div className="pt-[100px] pb-[45px]">
                                <p className="text-center font-exbold text-lg border-[#1D072E] p-1 border-solid border-2 text-[#1D072E ] ">Join Team</p>
                            </div>
                        </div>

                        <div className="bg-[#000] rounded-md border-[#C6A966] border-solid border-4 px-[91px] pt-[81px]">
                            <div className="flex flex-shrink-0 items-center gap-4 ">
                                <img src={LOGOWHITE} alt="HSB Logo" className="w-[55px]" />
                                <div className="HsbFc h-11 text-[#FFFFFF] text-[36px] font-exbold font-['Neue Plak']">
                                    HSB FC
                                </div>
                            </div>
                            <div className="text-center pt-3">
                                <div className="text-lg font-normal font-['Neue Plak'] text-[#FFFFFF]">
                                    NINE MONTHS
                                </div>
                                <div className="pt-1 text-5xl font-normal font-['Neue Plak'] text-[#FFFFFF]">
                                    $ 4.99
                                </div>
                                <div className="pt-1 text-lg font-normal font-['Neue Plak'] text-[#FFFFFF]">
                                    PER MONTH
                                </div>
                            </div>
                            <div className="w-[180px] pt-8 pl-8">
                                <ul className="divide-y divide-dashed">
                                    <li className="flex font-normal text-sm text-[#FFFFFF] pb-1">
                                        <img src={DoneWhite} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#FFFFFF] py-1">
                                        <img src={DoneWhite} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#FFFFFF] py-1">
                                        <img src={DoneWhite} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#FFFFFF] py-1">
                                        <img src={DisabledIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                </ul>
                            </div>
                            <div className="pt-[37px] pb-[45px]">
                                <p className="text-center font-exbold text-lg border-[#FFFFFF] p-1 border-solid border-2 text-[#FFFFFF]">Join Team</p>
                            </div>

                        </div>

                        <div className=" rounded-md border-[#060D0D] border-solid border-2 px-[91px] pt-[81px]">
                            <div className="flex flex-shrink-0 items-center gap-4">
                                <img src={LOGO} alt="HSB Logo" />
                                <div className="HsbFc h-11 text-zinc-950 text-[36px] font-exbold font-['Neue Plak']">
                                    HSB FC
                                </div>
                            </div>
                            <div className="text-center pt-3">
                                <div className="text-lg font-normal font-['Neue Plak'] text-[#1D072E]">
                                    ONE YEARS
                                </div>
                                <div className="pt-1 text-5xl font-normal font-['Neue Plak'] text-[#1D072E]">
                                    $ 3.99
                                </div>
                                <div className="pt-1 text-lg font-normal font-['Neue Plak'] text-[#1D072E]">
                                    PER MONTH
                                </div>
                            </div>
                            <div className="w-[170px] pt-8 pl-6">
                                <ul className="divide-y divide-dashed">
                                    <li className="flex font-normal text-sm text-[#1D072EBF] pb-1">
                                        <img src={DoneIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#1D072EBF] py-1">
                                        <img src={DoneIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#1D072EBF] py-1">
                                        <img src={DoneIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                    <li className="flex font-normal text-sm text-[#1D072EBF] py-1">
                                        <img src={DoneIcon} alt="Done icon" />
                                        100% Smooth Luxury
                                    </li>
                                </ul>
                            </div>
                            <div className="pt-[52px] pb-[45px]">
                                <p className="text-center font-exbold text-lg border-[#1D072E] p-1 border-solid border-2 text-[#1D072E ] ">Join Team</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Purchase