import React from 'react'
import Navbar from '../../components/NavBar';
import AboutUs from '../AboutUs/AboutUs';
import SlideBreak from '../../components/SlideBreak';
import Member from '../Member/Member';
import Partner from '../Partner/Partner';
import Media from '../Media/Media';
import JoinWithUs from '../JoinWithUs/JoinWithUs';
import Footer from '../../components/Footer';
import Banner from '../../assets/UI/banner.png';
import { useRef } from "react";

function Home() {
    const aboutUsRef = useRef(null);
    const memberRef = useRef(null);
    const activityRef = useRef(null);

    return (
        <div className="App relative">
            <Navbar sections={{ aboutUsRef, memberRef, activityRef }}></Navbar>
            <img className="w-full object-cover" src={Banner} alt="banner" />
            <AboutUs ref={aboutUsRef} />
            <SlideBreak
                titles={[
                    "HANOI START-UP BROTHERS FC",
                    "WITHOUT FOOTBALL MY LIFE IS NOTHING",
                    "HANOI START-UP BROTHERS FC",
                    "WITHOUT FOOTBALL MY LIFE IS NOTHING",
                    "HANOI START-UP BROTHERS FC",
                ]}
            />
            <Member ref={memberRef} />
            <SlideBreak
                titles={[
                    "HANOI START-UP BROTHERS FC",
                    "WITHOUT FOOTBALL MY LIFE IS NOTHING",
                    "HANOI START-UP BROTHERS FC",
                    "WITHOUT FOOTBALL MY LIFE IS NOTHING",
                    "HANOI START-UP BROTHERS FC",
                ]}
            />
            <Partner actRef={activityRef} />
            <Media ref={activityRef} />
            <JoinWithUs />
            <Footer />
        </div>
    )
}

export default Home
